export default function (functions) {
	const container = document.querySelector(".cookies-container");
	const save = document.querySelector(".cookies-save");
	const reject = document.querySelector("[js-rejeitar]");
	const body = document.body; // Captura o elemento body

	if (!container || !save || !reject) return null; // Verifica se todos os elementos existem

	const localPref = JSON.parse(window.localStorage.getItem("cookies-pref"));

	if (localPref) activateFunctions(localPref);
	else body.classList.add("no-scroll"); // Adiciona a classe para bloquear o scroll se não houver preferências

	function getFormPref() {
		return [...document.querySelectorAll(".cookies-pref [data-cookies]")]
			.filter((el) => el.checked)
			.map((el) => el.getAttribute("data-cookies"));
	}

	function activateFunctions(pref) {
		// pref.forEach((f) => functions[f]());
		container.style.display = "none";
		body.classList.remove("no-scroll"); // Remove a classe ao aceitar cookies
		window.localStorage.setItem("cookies-pref", JSON.stringify(pref));
	}

	function handleSave() {
		console.log("Cookies accepted"); // Mensagem de teste
		const pref = getFormPref();
		activateFunctions(pref);
	}

	function handleReject() {
		console.log("Cookies rejected"); // Mensagem de teste
		// Aqui não ativamos nenhuma função de cookies
		window.localStorage.setItem("cookies-pref", JSON.stringify([])); // Armazena uma lista vazia de preferências
		container.style.display = "none"; // Oculta o contêiner
		body.classList.remove("no-scroll"); // Remove a classe ao rejeitar cookies
	}

	save.addEventListener("click", handleSave);
	reject.addEventListener("click", handleReject); // Adicionado o evento de rejeitar
}

import axios from 'axios'
import showToastify from '../utils/toastify'
import 'toastify-js/src/toastify.css'
import { find } from 'utils/dom'
import IMask from 'imask'

export default function PageDenuncia() {
	const denuncia = find("[js-page='denuncia']")
	if (!denuncia) return

	const contatoFormSubmit = document.querySelector('#form-denuncia')

	function showMessage(response) {
		const message = response.data ? response.data.message : response.message
		showToastify(message)
		contatoFormSubmit.reset()
		setTimeout(enabledBtn, 1000)
	}

	const btnSubmit = document.querySelector('#form-denuncia button[type=submit]')

	function disabledBtn() {
		btnSubmit.disabled = true
		btnSubmit.classList.add('disabled')
	}

	function enabledBtn() {
		btnSubmit.disabled = false
		btnSubmit.classList.remove('disabled')
	}

	const getInputId = () =>{
		const inputId = document.querySelectorAll('#form-denuncia input[name=id]')
		inputId.forEach(i =>{
			i.addEventListener('change', (e) =>{
				const identificacao = document.querySelector('.item-identificacao')
				if(i.value ==='sim'){
					identificacao.classList.remove('hidden')
					console.log('Input id sim', i.value);
				}
				else{
					identificacao.classList.add('hidden')
					console.log('Input id nao', i.value);
				}
			})
		})
		
	}
	getInputId()

	contatoFormSubmit &&
		contatoFormSubmit.addEventListener('submit', function (event) {
			event.preventDefault()
			disabledBtn()

			const formData = new FormData()
			//formData.append('id', document.querySelector('#form-denuncia input[name=id]').value)
			if (document.querySelector('#form-denuncia input[name=nome]').value){
				formData.append('nome', document.querySelector('#form-denuncia input[name=nome]').value)
			} else{
			formData.append('nome', 'Usuário não identificado' )
			}

			if (document.querySelector('#form-denuncia input[name=sobrenome]').value){
				formData.append('sobrenome', document.querySelector('#form-denuncia input[name=sobrenome]').value)
			} else{
				formData.append('sobrenome', 'Usuário não identificado' )
			}
			
			//formData.append('sobrenome', document.querySelector('#form-denuncia input[name=sobrenome]').value) || 'Não identificado!'
			formData.append('area_setor_local', document.querySelector('#form-denuncia input[name=area_setor_local]').value)
			formData.append('unidade', document.querySelector('#form-denuncia input[name=unidade]').value)
			formData.append('setor', document.querySelector('#form-denuncia input[name=setor]').value)
			formData.append('tipo_denuncia', document.querySelector('#form-denuncia input[name=tipo_denuncia]').value)
			formData.append('pertence_canal_denuncia', document.querySelector('#form-denuncia input[name=pertence_canal_denuncia]:checked').value)
			formData.append('detalhes_ocorrido', document.querySelector('#form-denuncia textarea[name=detalhes_ocorrido]').value)

			console.log('Form denúncia', formData);

			const url = '/denuncia/'
			axios
				.post(url, formData)
				.then((response) => showMessage(response))
				.catch((response) => showMessage(response))
		})
}

import { gsap } from "gsap";
import Swiper from "swiper"
import logs from "../utils/logs";

export default function AnimaSobre() {
	gsap.registerPlugin(Draggable);

	const animaAbout = () => {
		var nav6 = $('#change').children('.holder').each(function (i) {
			TweenLite.set(this, { rotation: 90 * i });
		}).end();

		var tl = new TimelineLite({ paused: true });
		var nav = document.getElementById('change')

		var gorilla = gsap.to(nav, 60, { rotation: "360", ease: Linear.easeNone, repeat: -1 });


		$(document).on("mouseenter", ".holder .item-rotate", function () {
			tl.pause();
		}).on("mouseleave", ".holder .item-rotate", function () {
			tl.play();
		});


		const draggable = Draggable.create("#change", {
			type: "rotation",
			inertia: true,
			onDrag: function () {
				gorilla.pause
			}
		});
	}

	animaAbout()
	///// END /////

	const animaMenuScroll = () => {
		window.addEventListener("scroll", function () {
			const menuSobre = document.querySelectorAll(".item-change-color-scroll");
			const posicaoRolagem = window.pageYOffset || document.documentElement.scrollTop;

			if (posicaoRolagem > 100) { // Altere 200 para a posição desejada
				menuSobre.forEach(menuSobre => {
					menuSobre.classList.add("bg-branding-dark");
					menuSobre.classList.remove("gradientHeader");
				})
			} else {
				menuSobre.forEach(menuSobre => {
					menuSobre.classList.add("gradientHeader");
					menuSobre.classList.remove("bg-branding-dark");
				})
			}
		});
	}

	animaMenuScroll()
	///// END /////

	const initSwiperServices = () => {
		const swipers = document.querySelectorAll('.swiper-servico-mobile')

		swipers.forEach(el => {
			var swiper = new Swiper('.swiper-container', {
				slidesPerView: 'auto',
				spaceBetween: 8,
				navigation: {
					nextEl: '.swiper-servico-next',
					prevEl: '.swiper-servico-prev',
				},
				pagination: {
					el: '.swiper-pagination-servico',
					clickable: true,
					dynamicBullets: true,
					dynamicMainBullets: 3,
				},

				breakpoints: {
					// Quando a largura da janela for >= 640px
					640: {
						slidesPerView: 2,
					},
					// Quando a largura da janela for >= 768px
					768: {
						slidesPerView: 3,
					},
					// Quando a largura da janela for >= 1024px
					1024: {
						slidesPerView: 4,
					},
				}
			});

		})
	}

	initSwiperServices()
	///// END /////

	const changeSolutions = () => {
		const liSolutions = document.querySelectorAll('.ul-solutions li')
		const categoria = document.querySelectorAll('[data-categoria]')
		const conteudoCategoria = document.querySelectorAll('[data-categoria-conteudo]')

		categoria?.forEach(cat => {
			cat.addEventListener('click', (e) => {
				categoria.forEach(li => li.classList.remove('gradientSolutions'))
				cat.classList.toggle('gradientSolutions')

				categoria.forEach(li => li.classList.add('color-text-contato'))
				cat.classList.remove('color-text-contato')
				cat.classList.add('text-complementary-green-clean')
				conteudoCategoria.forEach(cont => {
					if (cat.dataset.categoria === cont.dataset.categoriaConteudo) cont.classList.remove('content-hidden')
					else cont.classList.add('content-hidden')
				})
			})
		})
	}

	changeSolutions()
	///// END /////

	//// CASES DE USO ////
	const changeHoverItens = () => {
		const itensHover = document.querySelectorAll('[data-titule]')
		itensHover.forEach(item => {
			item.addEventListener('mouseenter', () => {
				const itensEmpresa = document.querySelectorAll('.item-empresa')
				itensEmpresa.forEach(i => {
					if (i.dataset.titule == item.dataset.titule) {
						i.classList.remove('disable')
						//console.log('Iguais',item.dataset, i.dataset);
					}
					else {
						i.classList.add('disable')
						//console.log('Diferentes', item.dataset, i.dataset);
					}
				})
			})
		})
	}

	changeHoverItens()

	const gradientItens = () => {
		const itensHover = document.querySelectorAll('.item-change')
		itensHover.forEach(item => {
			item.addEventListener('mouseenter', () => {
				itensHover.forEach(i => i.classList.remove('item-case-active'))
				item.classList.add('item-case-active')

				const itensSolucoes = document.querySelectorAll('.itens-solucao')
				itensSolucoes.forEach(i => {
					if (i.dataset.titule == item.dataset.titule) {
						i.classList.remove('disable')
						//console.log('Iguais',item.dataset, i.dataset);
					}
					else {
						i.classList.add('disable')
						//console.log('Diferentes', item.dataset, i.dataset);
					}
				})
			})
		})
	}

	gradientItens()

	const padUl = () => {
		const ulCases = document.querySelectorAll('.call-me ul li')
		const ulWrapper = document.querySelector('.call-me ul')

		if (ulCases.length > 4 && !ulWrapper.classList.contains('pl-8')) ulWrapper.classList.add('pl-8')
		else if (ulCases.length < 4 && ulWrapper.classList.contains('pl-8')) ulWrapper.classList.remove('pl-8')
	}

	padUl()

	const swiperCounter = () => {
		const swiper = document.querySelectorAll('.swiper-personality')
		swiper?.forEach(i => {
			const slides = i?.querySelectorAll('swiper-slide')
			if (slides.length < 4) i?.classList.add('w-fit')
			else i?.classList.remove('w-fit')
		})
	}

	swiperCounter()
	///// END /////

	//// sobre a q13 ////

	function richTextParseAbout() {
		function parseJSONToHTML(json) {
			const data = JSON.parse(json);

			let html = "";

			for (const block of data.blocks) {
				switch (block.type) {
					case "paragraph":
						html += `<p class="paragraph">${block.data.text}</p>`;
						break;
					case "Header":
						html += `<h${block.data.level} class="header">${block.data.text}</h${block.data.level}>`;
						break;
					case "Table":
						html += `<table class="table">${block.data.content}</table>`;
						break;
					case "Raw":
						html += `<div class="raw">${block.data.html}</div>`;
						break;
					case "Delimiter":
						html += '<hr class="delimiter">';
						break;
					case "Code":
						html += `<pre class="code"><code>${block.data.code}</code></pre>`;
						break;
					case "Quote":
						html += `<blockquote class="quote">${block.data.text}</blockquote>`;
						break;
					case "List":
						const listItems = block.data.items
							.map((item) => `<li>${item}</li>`)
							.join("");
						const listStyle = block.data.style === "ordered" ? "ol" : "ul";
						html += `<${listStyle} class="list">${listItems}</${listStyle}>`;
						break;
					case "Embed":
						html += `<div class="embed ${block.data.service}">
									<iframe width="560" height="315" src="${block.data.embed}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
									</div>`;
						break;
					case "Checklist":
						const checklistItems = block.data.items
							.map((item) => {
								const checked = item.checked ? "checked" : "";
								return `<li>
										<input  type="checkbox" onclick="return false;"  ${checked}>
										<label> ${item.text} </label>
										</li>`;
							})
							.join("");
						html += `<ul>${checklistItems}</ul>`;
						break;
					case "Image":
						html += `<img src="${block.data.file.url}" alt="${block.data.caption}" class="image">`;
						break;
					case "Warning":
						html += `<div class="warning">
																<h3>${block.data.title}</h3>
																<p>${block.data.message}</p>
																</div>`;
						break;
					default:
						// Handle unknown block types here
						break;
				}
			}

			return html;
		}

		const blogContent = document.querySelectorAll(".text-content");
		blogContent.forEach((blog) => {
			if (blog.dataset.conteudoDescricaoEquipePt) {
				if (
					JSON.parse(blog.dataset.conteudoDescricaoEquipePt).blocks.length > 0
				) {
					const htmlParsed = parseJSONToHTML(
						blog.dataset.conteudoDescricaoEquipePt
					);
					blog.innerHTML = htmlParsed;
				} else {
					blog.closest("div").style.display = "none";
				}
			} else if (blog.dataset.conteudoDescricaoEquipeEn) {
				if (
					JSON.parse(blog.dataset.conteudoDescricaoEquipeEn).blocks.length > 0
				) {
					const htmlParsed = parseJSONToHTML(
						blog.dataset.conteudoDescricaoEquipeEn
					);
					blog.innerHTML = htmlParsed;
				} else {
					blog.closest("div").style.display = "none";
				}
			} else {
				blog.closest("div").style.display = "none";
			}
		});
	}
	richTextParseAbout();

	const showText = () => {
		const divImage = document.querySelectorAll('.text-expand')

		divImage.forEach(i => {

			i.addEventListener('click', (e) => {
				const expand = i.querySelector('.text-expand p')
				expand.classList.toggle('hidden')
				console.log('click', expand);
			})
		})
	}

	showText()
	///// END /////

	var swiper = new Swiper('.swiperParceiros', {
		slidesPerView: 'auto',
		spaceBetween: 24,
		autoplay: {
			delay: 3000, // Time between transitions in milliseconds
			loop: true,
			disableOnInteraction: false, // Keep autoplay active after user interactions
			pauseOnMouseEnter: true, // Pause autoplay on mouse enter
			waitForTransition: true, // Wait for transition to finish before continuing
		},

		navigation: {
			nextEl: '.swiper-parceiro-next',
			prevEl: '.swiper-parceiro-prev',
		},

		pagination: {
			el: '.swiper-pagination-parceiro',
			clickable: true,
			dynamicBullets: true,
			dynamicMainBullets: 3,
		}
	});

	var swiperClientes = new Swiper('.swiperClientes', {
		slidesPerView: 'auto',
		spaceBetween: 24,
		autoplay: {
			delay: 3000, // Time between transitions in milliseconds
			loop: true,
			disableOnInteraction: false, // Keep autoplay active after user interactions
			pauseOnMouseEnter: true, // Pause autoplay on mouse enter
			waitForTransition: true, // Wait for transition to finish before continuing
		},

		navigation: {
			nextEl: '.swiper-clientes-next',
			prevEl: '.swiper-clientes-prev',
		},
		pagination: {
			el: '.swiper-pagination-clientes',
			clickable: true,
			dynamicBullets: true,
			dynamicMainBullets: 3,
		}
	});

	var swiperQ13 = new Swiper('.swiperQ13', {
		slidesPerView: 'auto',
		spaceBetween: 8,

		navigation: {
			nextEl: '.swiper-custom-q13-next',
			prevEl: '.swiper-custom-q13-prev',
		},
		pagination: {
			el: '.swiper-pagination-custom-q13',
			clickable: true,
			dynamicBullets: true,
			dynamicMainBullets: 3,
		},

		breakpoints: {
			// Quando a largura da janela for >= 640px
			640: {
				slidesPerView: 2,
			},
			// Quando a largura da janela for >= 768px
			768: {
				slidesPerView: 3,
			},
			// Quando a largura da janela for >= 1024px
			1024: {
				slidesPerView: 4,
			},
		}
	});

	var swiperSocios = new Swiper('.swiperSocios', {
		slidesPerView: 'auto',
		spaceBetween: 8,

		navigation: {
			nextEl: '.swiper-sobre-a-q13-next',
			prevEl: '.swiper-sobre-a-q13-prev',
		},
		pagination: {
			el: '.swiper-pagination-sobre-a-q13',
			clickable: true,
			dynamicBullets: true,
			dynamicMainBullets: 3,
		}
	});

	const animaTextRotate = () => {
		// const centerX = 400;
		// const centerY = 400;
		// const radius = 450;

		const larguraTela = window.innerWidth;

		const centerX = larguraTela > 1280 ? 400 : 250;
		const centerY = larguraTela > 1280 ? 400 : 250;
		const radius = larguraTela > 1280 ? 450 : 375;

		function updatePosition(element, angle) {
			const x = centerX + radius * Math.cos(angle);
			const y = centerY + radius * Math.sin(angle);
			element.style.left = `${x}px`;
			element.style.top = `${y}px`;
			element.style.transform = `translate(-50%, -50%) rotate(${angle * 180 / Math.PI}deg)`;
		}

		function animate() {
			gsap.to(".floating-text-wrapper", {
				rotation: 360,
				duration: 30,
				repeat: -1,
				ease: "none",
				onUpdate: function () {
					document.querySelectorAll(".floating-text-wrapper").forEach((element, index) => {
						const angle = gsap.getProperty(element, "rotation") * Math.PI / 180 + (index * Math.PI / 1.5);
						updatePosition(element, angle);

						const text = element.querySelector('.floating-text');
						text.style.transform = `rotate(${-(angle * 180 / Math.PI)}deg)`; // Counteract the rotation
					});
				}
			});
		}

		animate();
	}

	const larguraTela = window.innerWidth;

	if (larguraTela > 1024) animaTextRotate()

	function richTextParseSobreNos() {
		function parseJSONToHTML(json) {
			const data = JSON.parse(json);

			let html = "";

			for (const block of data.blocks) {
				switch (block.type) {
					case "paragraph":
						html += `<p class="paragraph">${block.data.text}</p>`;
						break;
					case "Header":
						html += `<h${block.data.level} class="header">${block.data.text}</h${block.data.level}>`;
						break;
					case "Table":
						html += `<table class="table">${block.data.content}</table>`;
						break;
					case "Raw":
						html += `<div class="raw">${block.data.html}</div>`;
						break;
					case "Delimiter":
						html += '<hr class="delimiter">';
						break;
					case "Code":
						html += `<pre class="code"><code>${block.data.code}</code></pre>`;
						break;
					case "Quote":
						html += `<blockquote class="quote">${block.data.text}</blockquote>`;
						break;
					case "List":
						const listItems = block.data.items
							.map((item) => `<li>${item}</li>`)
							.join("");
						const listStyle = block.data.style === "ordered" ? "ol" : "ul";
						html += `<${listStyle} class="list">${listItems}</${listStyle}>`;
						break;
					case "Embed":
						html += `<div class="embed ${block.data.service}">
									<iframe width="560" height="315" src="${block.data.embed}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
									</div>`;
						break;
					case "Checklist":
						const checklistItems = block.data.items
							.map((item) => {
								const checked = item.checked ? "checked" : "";
								return `<li>
										<input  type="checkbox" onclick="return false;"  ${checked}>
										<label> ${item.text} </label>
										</li>`;
							})
							.join("");
						html += `<ul>${checklistItems}</ul>`;
						break;
					case "Image":
						html += `<img src="${block.data.file.url}" alt="${block.data.caption}" class="image">`;
						break;
					case "Warning":
						html += `<div class="warning">
																<h3>${block.data.title}</h3>
																<p>${block.data.message}</p>
																</div>`;
						break;
					default:
						// Handle unknown block types here
						break;
				}
			}

			return html;
		}

		const blogContent = document.querySelectorAll(".text-content-about");
		blogContent.forEach((blog) => {
			if (blog.dataset.conteudoDescricaoEquipePt) {
				if (
					JSON.parse(blog.dataset.conteudoDescricaoEquipePt).blocks.length > 0
				) {
					const htmlParsed = parseJSONToHTML(
						blog.dataset.conteudoDescricaoEquipePt
					);
					blog.innerHTML = htmlParsed;
				} else {
					blog.closest("div").style.display = "none";
				}
			} else if (blog.dataset.conteudoDescricaoEquipeEn) {
				if (
					JSON.parse(blog.dataset.conteudoDescricaoEquipeEn).blocks.length > 0
				) {
					const htmlParsed = parseJSONToHTML(
						blog.dataset.conteudoDescricaoEquipeEn
					);
					blog.innerHTML = htmlParsed;
				} else {
					blog.closest("div").style.display = "none";
				}
			} else {
				blog.closest("div").style.display = "none";
			}
		});
	}

	richTextParseSobreNos();

}